import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/eecinvestigation/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/eecinvestigation/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/eecinvestigation/${data.id}`, data);
    else return axios.post(`/api/eecinvestigation`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/eecinvestigation/${id}`);
  },
  changeStatusMulti: function (data) {
    return axios.patch(`/api/eecinvestigation/changestatusmulti`, data);
  },
};

<template>
  <div class="container-page Eeclnvestigation-card">
    <h2 class="title title--h2">Расследование ЕЭК, завершившееся без введения мер</h2>
    <div>
      <div class="form-group disabled-select required">
        <ComponentInput
          label="Группа товаров"
          :select2Settings="select2Settings"
          :select2Options="products"
          v-model="card.tnvedGroupId"
          :disabled="true"
          required
          type="select2"
        />
        <i class="fas fa-search catalog-icon" title="Найти в каталоге" @click="openGroupCatalog"></i>
      </div>
      <div class="form-group required">
        <ComponentInput
          label="Тип расследования"
          :select2Settings="select2Settings"
          :select2Options="investigationTypes"
          v-model="card.investigationTypeId"
          required
          type="select2"
        />
      </div>
      <div class="form-group required disabled-select">
        <ComponentInput
          label="Коды ТН ВЭД ЕАЭС"
          :select2Settings="select2SettingsMulti"
          :select2Options="products"
          v-model="card.tnvedIds"
          required
          multiple
          type="select2"
        />
        <i
          class="fas fa-search catalog-icon"
          title="Найти в каталоге"
          @click="openTnvedCatalog(index, 'tnvedBeforeId')"
        ></i>
      </div>
      <div class="form-group required">
        <ComponentInput
          label="Страна-экспортер"
          :select2Settings="select2SettingsMulti"
          :select2Options="countries"
          v-model="card.countryExporterIds"
          required
          multiple
          type="select2"
        />
      </div>
      <div class="row">
        <div class="form-group required col-6">
          <div class="form-label">Дата начала расследования</div>
          <DateComponent v-model="card.startDate" :clearable="false" required />
        </div>
        <div class="form-group required col-6">
          <div class="form-label">Дата завершения расследования</div>
          <DateComponent v-model="card.finishDate" :clearable="false" required />
        </div>
      </div>
      <div class="form-group required">
        <ComponentInput
          v-model="card.decision"
          label="Решение Коллегии ЕЭК"
          required
          type="textarea"
          placeholder="Введите решение Коллегии ЕЭК"
        />
      </div>
      <div class="form-group required">
        <ComponentInput
          v-model="card.companyApplicants"
          label="Компании-заявители"
          type="textarea"
          required
          placeholder="Укажите компаний-заявителей, каждую с новой строки"
        />
      </div>
      <div class="form-group required">
        <ComponentInput
          label="Страна базирования компаний-заявителей"
          :select2Settings="select2SettingsMulti"
          :select2Options="countries"
          v-model="card.countryApplicantIds"
          required
          multiple
          type="select2"
        />
      </div>
      <div class="form-group">
        <ComponentInput
          v-model="card.companySupportives"
          label="Компании, поддерживающие заявление"
          type="textarea"
          placeholder="Укажите компании, поддерживающие заявление, каждую с новой строки"
        />
      </div>
      <div class="form-group">
        <ComponentInput
          label="Страна базирования компаний, поддерживающих заявление"
          :select2Settings="select2SettingsMulti"
          :select2Options="countries"
          v-model="card.countrySupportiveIds"
          multiple
          type="select2"
        />
      </div>
      <div class="form-group">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="groupModal" title="Выбор группы товаров" @close="closeGroupCatalog">
      <CatalogSelector
        v-bind:selected-ids="productIds"
        route="nsitnved"
        :multiple="false"
        :select-groups-only="true"
        @select="updateGroup"
        @close="closeGroupCatalog"
      ></CatalogSelector>
    </ModalComponent>
    <ModalComponent v-model="tnvedModal" title="Выбор товара" @close="closeTnvedCatalog">
      <CatalogSelector
        v-bind:selected-ids="productIds"
        route="nsitnved"
        :multiple="true"
        :select-leafs-only="true"
        @select="updateTnved"
        @close="closeTnvedCatalog"
      ></CatalogSelector>
    </ModalComponent>
  </section>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import moment from 'moment';

  import Constants from '@/common/constants';

  import ComponentInput from '../../../common/components/ComponentInput';
  import DateComponent from '../../../common/components/dateComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import CatalogApi from '../../nsi/api/catalog';
  import CountryApi from '../../nsi/api/country';
  import NsiApi from '../../nsi/api/nsi';
  import CatalogSelector from '../../nsi/components/CatalogSelector';
  import Api from '../api/eecinvestigation';

  export default {
    name: 'EecInvestigationCard',
    components: {
      DateComponent,
      ModalComponent,
      CatalogSelector,
      ButtonStock,
      ComponentInput,
    },
    data() {
      return {
        id: this.$route.params.id,
        card: {
          id: 0,
        },
        investigationTypes: [],
        countries: [],
        products: [],
        groupModal: false,
        tnvedModal: false,
        productIds: [],
        currentTnvedIndex: -1,
        currentTnvedProp: '',
        select2Settings: Constants.select2Settings,
        select2SettingsMulti: Constants.select2SettingsMultiple,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      loadDictionaries() {
        NsiApi.search('nsiinvestigationtype', {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        })
          .then((response) => {
            this.investigationTypes = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        CountryApi.search({ pageNumber: 1, pageSize: 0 })
          .then((response) => {
            this.countries = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        if (moment(this.card.finishDate) < moment(this.card.startDate)) {
          Constants.alert.fire('Ошибка', 'Дата окончания расследования не может быть меньше даты его начала.', 'error');
          return;
        }

        Api.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.$router.push({
                name: 'EecInvestigationCard',
                params: { id: this.card.id, action: 'edit' },
              });
            } else {
              this.$router.push({ name: 'EecInvestigationRegistry' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      openGroupCatalog() {
        this.productIds = this.card.tnvedGroupId ? [this.card.tnvedGroupId] : [];
        this.groupModal = true;
      },
      updateGroup(selectedIds) {
        this.card.tnvedGroupId = selectedIds[0];
        this.closeGroupCatalog();
      },
      closeGroupCatalog() {
        this.groupModal = false;
      },
      openTnvedCatalog(index, prop) {
        this.currentTnvedIndex = index;
        this.currentTnvedProp = prop;
        this.productIds = this.card.tnvedIds ?? [];
        this.tnvedModal = true;
      },
      updateTnved(selectedIds) {
        this.card.tnvedIds = selectedIds;
        this.closeTnvedCatalog();
      },
      closeTnvedCatalog() {
        this.tnvedModal = false;
      },
      updateProducts(list) {
        list.forEach((item) => {
          if (!this.products.find((x) => x.id === item.id)) {
            this.products.push(item);
          }
        });
      },
      close() {
        this.$router.push({ name: 'EecInvestigationRegistry' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
    watch: {
      'card.tnvedGroupId': {
        handler: function (value) {
          if (!value) return;
          CatalogApi.getListByIds('nsitnved', [value]).then((response) => {
            this.updateProducts(response.data.map((x) => ({ id: x.id, text: x.name })));
          });
        },
        deep: true,
      },
      'card.tnvedIds': {
        handler: function (value) {
          if (!value?.length) return;
          CatalogApi.getListByIds('nsitnved', value).then((response) => {
            this.updateProducts(
              response.data.map((x) => ({
                id: x.id,
                text: `${x.name} (${x.code})`,
              })),
            );
          });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  .col-tnved {
    width: 25%;
  }

  .col-rate {
    width: 10%;
  }

  .stockTable {
    .catalog-icon {
      top: 18px;
    }
  }
</style>

<style lang="scss">
  .select2-container--default {
    .select2-selection--multiple {
      .select2-selection__clear {
        margin-right: 18px !important;
        margin-top: -8px !important;
      }
    }
  }
</style>
